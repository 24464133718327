body {
  background-color: #f5f5f5;
}

.content {
  width: 40vw;
}

.card {
  border-radius: 0.75rem !important;
  box-shadow: 0 0.375rem 0.625rem -0.25rem rgba(0, 0, 0, 0.15) !important;
}

.card .card-header {
  border-bottom: 0.125rem solid rgba(0, 0, 0, 0.125);
}
